import React, { useState } from "react";

import companyLogo from "../assets/logo.png";
import { useTranslation } from "react-i18next";

import { Link, NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";

import { useMediaQuery } from "react-responsive";

import "../css/Navbar.css";
import "../css/Header.css";
import { Accordion, Dropdown } from "react-bootstrap";
import TranslatorComponent from "./TranslatorComponent";

function Header() {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1150px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1150px)" });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: "1150px" });
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMobileMenu = () => {
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const renderNavLinks = () => {
    const listClassName = isMobile ? "nav__list" : "nav__list__web";
    const linkClassName = "nav__link";
    //const buttonClassName = "nav__cta";

    return (
      <ul className={listClassName}>
        <li>
          <NavLink to="/" className={linkClassName} onClick={closeMobileMenu}>
            {t("main.header.home")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about-us"
            className={linkClassName}
            onClick={closeMobileMenu}
          >
            {t("main.header.aboutUs")}
          </NavLink>
        </li>
        {isTabletOrMobile && (
          <Accordion className="accordion-mobile-header">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {t("main.header.servicesDropdown.services")}
              </Accordion.Header>
              <Accordion.Body>
                <Link to={"/fund-management"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.fundManagement")}
                </Link>
                <Link to={"/certificates"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.certificates")}
                </Link>
                <Link to={"/asset-management"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.gestionePat")}
                </Link>
                <Link to={"/financial-consulting"} onClick={closeMobileMenu}>
                  {t("main.header.servicesDropdown.consulting")}
                </Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {isDesktop && (
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
          >
            <Dropdown.Toggle className="services-toggle" id="dropdown-services">
              {t("main.header.servicesDropdown.services")}
            </Dropdown.Toggle>
            <Dropdown.Menu show={showDropdown} className="services-menu">
              <Dropdown.Item href="/fund-management">
                {t("main.header.servicesDropdown.fundManagement")}
              </Dropdown.Item>
              <Dropdown.Item href="/certificates">
                {t("main.header.servicesDropdown.certificates")}
              </Dropdown.Item>
              <Dropdown.Item href="/asset-management">
                {t("main.header.servicesDropdown.gestionePat")}
              </Dropdown.Item>
              <Dropdown.Item href="/financial-consulting">
                {t("main.header.servicesDropdown.consulting")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <li>
          <NavLink
            to="/complaints"
            className={linkClassName}
            onClick={closeMobileMenu}
          >
            {t("main.header.complaints")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact-us"
            className={linkClassName}
            onClick={closeMobileMenu}
          >
            {t("main.header.contacts")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="https://rigsavecapital.netlify.app/#/login"
            className={linkClassName}
            onClick={closeMobileMenu}
          >
            {t("main.header.icashly")}
          </NavLink>
        </li>
        <TranslatorComponent />
      </ul>
    );
  };

  return (
    <header className="header">
      <nav className="nav">
        <NavLink to="/" className="nav__logo">
          <img height={80} src={companyLogo} alt="Andromeda logo" />
        </NavLink>

        {isMobile && (
          <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
            <IoMenu />
          </div>
        )}

        {isMobile ? (
          <div
            className={`nav__menu  ${isMenuOpen ? "show-menu" : ""}`}
            id="nav-menu"
          >
            {renderNavLinks()}
            <div className="nav__close" id="nav-close" onClick={toggleMenu}>
              <IoClose />
            </div>
          </div>
        ) : (
          renderNavLinks()
        )}
      </nav>
    </header>
  );
}

export default Header;
