import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";

import { Link } from "react-router-dom";

import "../css/Documents.css";
import { RotatingLines } from "react-loader-spinner";

function Complaints() {
  const { t } = useTranslation();

  const [jsonObject, setJsonObject] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const [complaintsHandlingPolicy, setComplaintsHandlingPolicy] = useState("");

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);

    const filteredItems = jsonObject.filter((pdf) =>
      pdf.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  };

  async function downlaodFiles() {
    await getUrl({
      key: "complaints/Rigsave_Capital_Complaints_Handling_Policy.pdf",
    }).then((result) => {
      setComplaintsHandlingPolicy(result.url.toString());
    });
  }

  useEffect(() => {
    Promise.all([downlaodFiles()]).then((responses) => {
      const tempArrayResults = [
        {
          name: ` ${t("main.complaints.handlingPolicy")}`,
          url: complaintsHandlingPolicy,
        },
      ];
      setFilteredData(tempArrayResults);
      setJsonObject(tempArrayResults);
    });
  }, [complaintsHandlingPolicy, t]);

  return (
    <div className="container wrapper-documents pt-5 mt-5 mb-5 pb-5">
      <div className="internal-wrap pt-5 mt-5 pb-5 mb-5">
        <div className="rigsave-custom-title text-center pb-5">
          {t("main.complaints.title")}
        </div>
        {/* <div className="text-center pb-5 mb-4">
          <input
            type="text"
            className="input-search"
            onChange={handleInputChange}
            value={searchValue}
          />
        </div> */}
        {filteredData ? (
          <div className="results-wrapper">
            {filteredData.map((pdf, i) => {
              return (
                <div
                  key={i}
                  className="col-lg-6 card-documents px-5 pt-4 pb-4 mx-5 mb-5"
                >
                  <div className="title-documents">{pdf.name}</div>
                  <Link
                    to={pdf.url}
                    className="button-contact final-a list-inline d-block text-center"
                  >
                    PDF
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="container justify-content-center text-center">
            <RotatingLines
              strokeColor="#6b97cf"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Complaints;
